<template>
  <div
    class="tl can-res text-center"
    :style="{width: width}"
    @dragenter="setDragenterRowAndIndex"
  >
    <v-icon v-if="dayOff" name="fa-calendar-times" scale="4"/>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    rowIndex: Number,
    keyIndex: Number,
    width: String,
    dayOff: Boolean,
  },
  data() {
    return {
    }
  },
  methods: {
    setDragenterRowAndIndex() {
      this.$emit("set-dragenter-row-and-index", this.rowIndex, this.keyIndex);
    },
  }
}
</script>

<style scoped>
@import "schedule.css";
</style>